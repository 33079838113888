import React from 'react';
import Helmet from 'react-helmet';
import '../../assets/scss/init.scss';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="layout">
        <Helmet defaultTitle="Avraam Mavridis">
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            rel="stylesheet"
            media="print"
            onLoad="this.onload=null;this.removeAttribute('media');"
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500;1,900&display=swap"
          />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-X1MN1NMTWG" />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-X1MN1NMTWG');
            `}
          </script>
        </Helmet>
        {children}
      </div>
    );
  }
}

export default Layout;
